import { createRouter, createWebHistory } from 'vue-router';
import CheckoutCart from '@/views/CheckoutCart.vue';
import { useCheckoutStore } from '@/stores/checkout';

const quantityLimit = Number(import.meta.env.VITE_QUANTITY_LIMIT);

const routes = [
  {
    // while checkout is the only available view, all paths should lead to it
    path: '/:catchAll(.*)*',
    redirect: '/checkout/',
  },
  {
    path: '/checkout/',
    name: 'CheckoutCart',
    component: CheckoutCart,
    meta: {
      title: 'Checkout',
    },
    props: route => ({
      plan: route.query.plan,
      coupon: route.query.coupon,
      quantity: Number(route.query.quantity),
      servers: Number(route.query.servers),
      inapp: Number(route.query.inapp),
      couponFocus: Boolean(route.query.couponFocus),
    }),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(to => {
  /* eslint-disable no-param-reassign */
  
  if (to.name === 'CheckoutCart' && !to.query.plan) {
    to.query.plan = 'speedify-unlimited-monthly';
  }

  if (to.query.coupon) {
    to.query.coupon = to.query.coupon.trim().toLowerCase();
  }

  if (to.query.quantity) {
    to.query.quantity = Number(to.query.quantity);
  }
  if (to.query.servers) {
    to.query.servers = Number(to.query.servers);
  }
  if (to.query.inapp) {
    to.query.inapp = Number(to.query.inapp);
  }

  if (to.query.quantity > quantityLimit) {
    to.query.quantity = quantityLimit;
  }
  if (to.query.servers > quantityLimit) {
    to.query.servers = quantityLimit;
  }
  /* eslint-enable no-param-reassign */
});

router.afterEach((to, from) => {
  const checkoutStore = useCheckoutStore();
  if (to.path === '/checkout/'){
    const serverCodeInterval = to.query.plan.includes('monthly') ? 'monthly' : 'yearly';
    const newCheckoutData = {
      plan: to.query.plan || '',
      quantity: to.query.quantity || 1,
      servers: to.query.servers || 0,
      coupon: to.query.coupon || '',
      serverCode: `speedify-dedicated-server-${serverCodeInterval}`,
    };
    checkoutStore.update(newCheckoutData);
    checkoutStore.getPlanDescription();
  }

  if (to.meta?.title) {
    // eslint-disable-next-line no-undef
    document.title = `${to.meta.title} | Speedify`;
  }
});

export default router;
